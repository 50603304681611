<template>
  <div class="right-side-container">
   <div class="row">
      <div class="col-sm-12">
         <div class="web-title">
            <h2>Roles & Permission</h2>
         </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
         <div class="web-title">
            <h4>{{rolename}}</h4>
            <b-button size="sm" variant="danger" @click="savePermission()">
          Save
        </b-button>
         </div>
      </div>
    </div>
    <div class="row">
      <ul class="perlistcontainer">
        <li v-for="item in items" v-bind:key="item.id">
          <label :for="item.controller_name">{{item.controller_name}}</label>
          <ul class="perlist">
            <li v-for="screen in item.screens" v-bind:key="screen.id">
              <input type="checkbox" :value="screen.id" v-model="form.permissions" :id="screen.screen_scope_name"><label :for="screen.screen_scope_name">{{screen.screen_name}}</label>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<style>
.perlistcontainer>li>label {text-transform: uppercase;font-weight: 700;}
.perlistcontainer>li {padding: 15px;background: #fff;margin: 5px;max-width: 30%;width: 100%;}
.perlistcontainer {display: flex;flex-wrap: wrap;padding: 20px;list-style: none;}
.perlist, .perlistcontainer>li>label{color: #095169;}
.perlist {list-style: none;margin: 0;padding: 10px 0;border-top: 1px solid #e8e8e8;}
.perlist input {margin-right: 5px;}
</style>
<script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import { getModulesWithScreens, getRoleDetails, permissionListByRole } from '@/api/access'
  import Form from '@/core/services/api.form.services'
  export default {
    data() {
      return {
        loading:false,
        items: [],
        rolename:null,
        form:new Form({
          role_id:null,
          permissions:[]
        })
      }
    },
    created() {
      this.fetchData(1)
      let roleId = this.$route.params.roleId;
      if(parseInt(roleId) > 0){
        getRoleDetails(roleId).then(response => {
          if(response.data.data.id){
            this.form.role_id = response.data.data.id
            this.rolename = response.data.data.role
          }
        });

        permissionListByRole(roleId).then(response => {
          this.form.permissions = response.data.data
          this.loading = false
        })
      }
    },
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Roles", route: "/role/list" },
      { title: "Role Permission" }
    ]);
    },
    methods: {
      savePermission(){
        this.loading=true;
        this.form.post('permission/create').then((response) => {
          if(response.status){
              this.$router.push({ path: '/role/list' })
              this.loading=false;
              this.$notify({
                group: 'scorecard',
                type: 'success',
                text: 'Permission created successfully.',
                speed: 1000
              });
          }
        })
      },
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.q){
          params.q = this.q
        }
        if(this.sort){
          params.sort = this.sort;
        }
        getModulesWithScreens().then(response => {
          this.items = response.data.data
          this.loading = false
        })
      },
    }
  }
</script>